import React from 'react';
import clsx from 'clsx';
import BackgroundImage from 'gatsby-background-image';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background,
  },
  backgroundImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  credit: {
    '&:hover': {
      opacity: 1,
      textShadow: '-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
    },
    position: 'absolute',
    color: 'white',
    bottom: 0,
    right: 0,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    opacity: 0.05,
    textAlign: 'right',
  },
}));

const FullScreenImage = ({ fluid, credit, backgroundClassName, children }) => {
  const classes = useStyles();
  return (
    <BackgroundImage
      Tag="section"
      fluid={fluid}
      className={clsx(classes.root, classes.backgroundImage, backgroundClassName)}
    >
      {children}
      <Typography variant="caption" className={classes.credit}>
        {credit}
      </Typography>
    </BackgroundImage>
  );
};

export default FullScreenImage;